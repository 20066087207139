import React from "react";
import line from "../assets/imgs/line.png";
import Dan from "../assets/imgs/Daniel.png";
import DanSignature from "../assets/imgs/Daniel-signature.png";
import linkedin from "../assets/imgs/linkedin.svg";

function DanSection() {
  return (
    <article className="sebaSection dan">
      {/* left part */}
      <div className="sebaSection-right">
        <div className="purpose-section-right">
          <div className="purpose-section-right-header">
            <div className="purpose-section-right--header">
              <img src={line} alt="NextPatron" />
              <h3>Structure Drives Behavior</h3>
            </div>
            <h2>Meet Daniel</h2>
          </div>
          <div className="sebaSection-data">
            <p>
              Daniel is a trained expert natural resources trader and holds
              Masters of Arts in International Trading, Commodity Finance and
              Shipping as well as in Accounting and Finance. Daniel comes from a
              family of entrepreneurs and has ever since managed companies.
              Additionally, he brought valuable experience from the wealth
              management side which he has acquired during his time in Private
              Banking.
              <br />
              <br />
              His experiences include:
              <br />
              <br />
            </p>
            <ul>
              <li>Large scale financing facilities in resource trading</li>
              <li>Derivative structuring and trading</li>
              <li>Resource trading using certificates</li>
              <li>On the ground UN Observer (UNTSO)</li>
              <li>Operational Roles as CEO, CFO </li>
              <li>Member of the Board in several companies</li>
            </ul>
          </div>
          <div className="sebaSection-cta">
            <a
              href="mailto:daniel@nextpatron.ch"
              target="_blank"
              className="btn-primary"
            >
              Let's Talk
            </a>
            <a href="https://calendly.com/nextpatron" className="btn-outline">
              Let's Meet
            </a>
          </div>
        </div>
      </div>

      {/* right part */}
      <div className="sebaSection-left">
        <img src={Dan} alt="Daniel NextPatron" className="seba" />
        <div className="danSection-left-data">
          <h3>Daniel</h3>
          <h4>Entrepreneur and VC Investor</h4>
          <p>
            «Early bird catches the fish, since what else would you do with a
            worm?”
          </p>
          <div className="seba-sm">
            <img
              src={DanSignature}
              className="signature"
              alt="Daniel NextPatron"
            />
            <div>
              <a
                href="https://www.linkedin.com/in/daniel-z%C3%BCrcher-69964aa"
                target="_blank"
              >
                <img src={linkedin} alt="Linkedin Daniel nextpatron" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}

export default DanSection;
