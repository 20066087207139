import React from "react";
import line from "../assets/imgs/line.png";
import Seba from "../assets/imgs/sebatiankopp.png";
import SebaSignature from "../assets/imgs/seba-signature.png";
import discord from "../assets/imgs/discord.svg";
import linkedin from "../assets/imgs/linkedin.svg";

function SebaSection() {
  return (
    <article id="MeetTeam" className="sebaSection">
      {/* left part */}
      <div className="sebaSection-left">
        <img src={Seba} alt="Sebatian Kopp NextPatron" className="seba" />
        <div className="sebaSection-left-data">
          <h3>Sebastian </h3>
          <h4>Entrepreneur and VC Investor</h4>
          <p>“You miss 100% of all shots you don’t take.”(Wayne Gretzky)</p>
          <div className="seba-sm">
            <img
              src={SebaSignature}
              className="signature"
              alt="Sebatian Kopp NextPatron"
            />
            <div>
              <a
                href="https://www.linkedin.com/in/sebastian-koepp-medina"
                target="_blank"
              >
                <img src={linkedin} alt="Linkedin Sebatian nextpatron" />
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* right part */}
      <div className="sebaSection-right">
        <div className="purpose-section-right">
          <div className="purpose-section-right-header">
            <div className="purpose-section-right--header">
              <img src={line} alt="NextPatron" />
              <h3>People drive ideas</h3>
            </div>
            <h2>Meet Sebastian</h2>
          </div>
          <div className="sebaSection-data">
            <p>
              Sebastian is a trained Economist with a wide experience in
              Corporate Finance and General Management in Entrepreneurial
              Environments. He has sold and bought a large number of companies
              in various roles, rose two digit Millions in Funds and
              grown/reorganized companies +/-150 employees.
              <br />
              <br />
              His experiences include:
              <br />
              <br />
            </p>
            <ul>
              <li>Small to Large Scale M&A Transactions (1bn+ in total)</li>
              <li>
                Several multi-million Venture Capital Rounds in Startup
                Companies
              </li>
              <li>Startup Exits</li>
              <li>Operational Roles as Head of Europe, COO, CFO</li>
              <li>Member of the Board in several companies</li>
            </ul>
          </div>
          <div className="sebaSection-cta">
            <a
              href="mailto:sebastian@nextpatron.ch"
              target="_blank"
              className="btn-primary"
            >
              Let's Talk
            </a>
            <a href="https://calendly.com/nextpatron" className="btn-outline">
              Let's Meet
            </a>
          </div>
        </div>
      </div>
    </article>
  );
}

export default SebaSection;
