import "./App.css";
import DanSection from "./components/DanSection";
import FooterSection from "./components/FooterSection";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import PortflioSection from "./components/PortflioSection";
import ProcessSection from "./components/ProcessSection";
import PurposeSection from "./components/PurposeSection";
import SebaSection from "./components/SebaSection";

function App() {
  return (
    <>
      <Header />
      <main>
        <HeroSection />
        <PurposeSection />
        <ProcessSection />
        <SebaSection />
        <DanSection />
        <PortflioSection />
      </main>
      <FooterSection />
    </>
  );
}

export default App;
