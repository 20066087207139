import React from "react";
import logo from "../assets/imgs/logo-nextpatron.svg";

function FooterSection() {
  return (
    <footer>
      <div className="footer">
        <div className="footer-content">
          <img className="footer-logo" src={logo} alt="NextPatron Logo" />
          <div className="footer-data">
            <div>
              <h6>Office</h6>
              <address>
                Next Patron AG
                <br />
                Erlenstrasse 59
                <br />
                CH - 8832 Wollerau
              </address>
            </div>
            <div>
              <h6>Contact</h6>
              <a href="tel:+41797031539">+41797031539</a>
              <br />
              <a href="mailto:admin@nextpatron.ch">admin@nextpatron.ch</a>
            </div>
          </div>
        </div>

        <p className="footer-bottom">© NextPatron</p>
      </div>
    </footer>
  );
}

export default FooterSection;
