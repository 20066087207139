import React from "react";

function HeroSection() {
  return (
    <article className="hero-section">
      <div className="hero-section-content">
        <div>
          <h1 className="hero-section--title">We provide your first funding</h1>
          <p className="hero-section--content">
            We are the VC we would have loved to work with in our own startups.
          </p>
        </div>
        <div className="hero-section-btns">
          <a
            href="https://calendly.com/nextpatron"
            target="_blank"
            className="btn-primary"
          >
            Get in Touch
          </a>
          <a href="#OURPURPOSE" className="btn-outline">
            Learn More
          </a>
        </div>
      </div>
    </article>
  );
}

export default HeroSection;
