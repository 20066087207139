import React from "react";
import blink from "../assets/imgs/blink.png";
import fs from "../assets/imgs/FS.png";
import ava from "../assets/imgs/ava.png";
import coatingAi from "../assets/imgs/coatingAi.png";

function PortflioSection() {
  const data = [
    { id: 1, img: blink, link: "https://blinkdrive.ch/" },
    { id: 2, img: coatingAi, link: "https://coatingai.com/" },
    { id: 3, img: fs, link: "https://founderstudio.io/corporate" },
    { id: 4, img: ava, link: "https://www.ava.com/" },
  ];
  return (
    <article id="Portolio" className="PortflioSection">
      <div className="PortflioSection--header">
        <h3>Check our Portfolio</h3>
        <p>We have to date invested into 4 portfolio companies </p>
      </div>
      <div className="PortflioSection--data">
        {data.map((i) => (
          <a
            className="portfolio_link"
            key={i.id}
            href={i.link}
            target="_blank"
          >
            <img src={i.img} alt="NextPatron portfolio" />
          </a>
        ))}
      </div>
    </article>
  );
}

export default PortflioSection;
