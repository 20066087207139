import React from "react";
import line from "../assets/imgs/line.png";

function ProcessSection() {
  return (
    <article id="OURPROCESS" className="process-section">
      <div className="purpose-section-right mb-140">
        <div className="purpose-section-right-header">
          <div className="purpose-section-right--header">
            <img src={line} alt="NextPatron" />
            <h3>OUR PROCESS</h3>
          </div>
          <h2>How we operate</h2>
        </div>
      </div>

      <div className="process-content">
        {/* left */}
        <div className="process-content-card">
          {/* number + line */}
          <div className="process-content-card--num">
            <h2>01</h2>
            <img src={line} alt="NextPatron" />
          </div>
          {/* h3 + p */}
          <div>
            <h3>Pitch us the opportunity</h3>
            <p>
              We are mainly opportunistic investors. As long as it is an
              attractive deal to us we will look at it.
            </p>
          </div>
        </div>
        {/* center */}
        <div
          className="process-content-card"
          style={{ justifyContent: "center" }}
        >
          {/* number + line */}
          <div className="process-content-card--num">
            <h2>02</h2>
            <img src={line} alt="NextPatron" />
          </div>
          {/* h3 + p */}
          <div>
            <h3>Let’s have a chat</h3>
            <p>
              We want to know you, your business, your co-founders, your future
              plans and understand why you need money.
            </p>
          </div>
        </div>
        {/* right */}
        <div className="process-content-card" style={{ justifyContent: "end" }}>
          {/* number + line */}
          <div className="process-content-card--num">
            <h2>03</h2>
            <img src={line} alt="NextPatron" />
          </div>
          {/* h3 + p */}
          <div>
            <h3>Due Diligence / Signing / Closing</h3>
            <p>
              We usually are pretty fast when it comes to closing a deal (when
              it is required), but we will always do our Due Diligence.
            </p>
          </div>
        </div>
      </div>
    </article>
  );
}

export default ProcessSection;
