import React, { useState } from "react";
import logo from "../assets/imgs/logo-nextpatron.svg";
import menu from "../assets/imgs/menu.svg";
import close from "../assets/imgs/close.svg";

function Header() {
  const [showMenu, setshowMenu] = useState(false);
  const toggleMenu = () => setshowMenu(!showMenu);

  return (
    <header>
      <aside className={`sidebar ${showMenu ? "showMenu" : ""}`}>
        <img
          onClick={() => setshowMenu(false)}
          className="closeBtn"
          src={close}
          alt="NextPatron Close"
        />
        <a
          onClick={() => setshowMenu(false)}
          className="sm-link"
          href="#OURPURPOSE"
        >
          Why us?
        </a>
        <a
          onClick={() => setshowMenu(false)}
          className="sm-link"
          href="#OURPROCESS"
        >
          How it works?
        </a>
        <a
          onClick={() => setshowMenu(false)}
          className="sm-link"
          href="#Portolio"
        >
          Portfolio
        </a>
        <a
          onClick={() => setshowMenu(false)}
          className="sm-link"
          href="#MeetTeam"
        >
          Team
        </a>

        <a
          href="https://calendly.com/nextpatron"
          target="_blank"
          className="btn-primary"
        >
          Get in Touch
        </a>
      </aside>
      <nav id="navbar">
        <div className="nav">
          <div className="nav-content">
            <img
              className="logo"
              src={logo}
              alt="NextPatron Logo"
              height={40}
            />
            <img
              onClick={toggleMenu}
              className="hamburger-menu"
              src={menu}
              alt="NextPatron"
            />
            <div className="sm-links">
              <a className="sm-link" href="#OURPURPOSE">
                Why us?
              </a>
              <a className="sm-link" href="#OURPROCESS">
                How it works?
              </a>
              <a className="sm-link" href="#Portolio">
                Portfolio
              </a>
              <a className="sm-link" href="#MeetTeam">
                Team
              </a>
            </div>
            <a
              href="https://calendly.com/nextpatron"
              target="_blank"
              className="btn-primary"
            >
              Get in Touch
            </a>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
