import React from "react";
import line from "../assets/imgs/line.png";
import coins from "../assets/imgs/coins 1.svg";
import rocket from "../assets/imgs/rocket-launch 1.svg";
import knight from "../assets/imgs/chess-knight 1.svg";
import trophy from "../assets/imgs/ban.png";

function PurposeSection() {
  return (
    <article id="OURPURPOSE" className="purpose-section">
      <div className="purpose-section-right">
        <div className="purpose-section-right-header">
          <div className="purpose-section-right--header">
            <img src={line} alt="NextPatron" />
            <h3>OUR PURPOSE</h3>
          </div>
          <h2>
            Why We Are
            <br />
            The Right Partner
          </h2>
        </div>
        <a
          href="https://calendly.com/nextpatron"
          target="_blank"
          className="btn-primary"
        >
          Let's Talk
        </a>
      </div>

      <div className="purpose-section-cards">
        <div>
          <div
            className="purpose-section-card"
            style={{ marginTop: "24px", borderRadius: "24px 24px 4px 24px" }}
          >
            <img src={coins} height={48} width={48} alt="NextPatron" />
            <div>
              <h3>Leading your first financing round</h3>
              <p>
                We provide reasonable advice, hands-on due diligence and
                entrepreneurial terms
              </p>
            </div>
          </div>
          <div
            className="purpose-section-card"
            style={{ borderRadius: "24px 4px 24px 24px" }}
          >
            <img src={knight} height={48} width={48} alt="NextPatron" />
            <div>
              <h3>Adding strategic value</h3>
              <p>
                We only believe in businesses we understand, think we can add
                value and are familiar with. Bringing in our full network
                boosting our portfolio companies.
              </p>
            </div>
          </div>
        </div>
        <div>
          <div
            className="purpose-section-card"
            style={{ borderRadius: "24px 24px 24px 4px" }}
          >
            <img src={rocket} height={48} width={48} alt="NextPatron" />
            <div>
              <h3>Backed by entrepreneurs</h3>
              <p>
                Having led, sold, grown and still run several companies we are
                not just investors, we are entrepreneurs who became investors.
              </p>
            </div>
          </div>
          <div
            className="purpose-section-card"
            style={{ borderRadius: " 4px 24px 24px 24px" }}
          >
            <img src={trophy} height={48} width={48} alt="NextPatron" />
            <div>
              <h3>No Bullshit</h3>
              <p>
                We appreciate Founders with big dreams, visions and ambitions,
                but we never forget that every company at some point needs to
                turn profitable.
              </p>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}

export default PurposeSection;
